<template>
    <VVVLayoutNew>
        <template #page-title>
            User Profile
        </template>
        <nav aria-label="breadcrumb" style="font-size: 13px;">
            <MDBBreadcrumb>
                <MDBBreadcrumbItem><a @click.prevent="goBack" class="text-primary" style="cursor: default;">Back to
                        previous</a>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active style="cursor: default;">
                    User Profile
                </MDBBreadcrumbItem>
            </MDBBreadcrumb>
        </nav>
        <div class="backdrop" v-if="loadingSpinner">
            <div class="image-container d-flex justify-content-center align-items-center">
                <div class="loader"></div>
                <img src="@/assets/VVV/vvv-loader.png" alt="Loading image">
            </div>
        </div>
        <div class="d-block d-md-flex gap-3">
            <div class="w-100">
                <MDBCard class="shadow-sm w-100 mb-3">
                    <MDBCardBody class="d-block d-md-flex gap-3 align-items-center">
                        <div class="d-flex gap-3 align-items-center mb-md-0 mb-4">
                            <div class="default-avatar shadow-lg me-1" v-if="!usersStore.users.profilePictureUrl">
                                <span class="avatar-letter">{{ avatarLetter }}</span>
                            </div>
                            <img v-else :src="usersStore.users.profilePictureUrl" alt="Profile Picture"
                                class="profile-avatar shadow-lg me-1" />
                            <div class="text-wrap">
                                <h6 class="fw-bold my-1 text-break">{{ usersStore.users.firstName }} {{
                                    usersStore.users.lastName
                                    }}</h6>
                                <span class="align-bottom text-break">{{ usersStore.users.email }}</span>
                            </div>
                        </div>
                        <div class="d-flex gap-2 ms-auto">
                            <input type="file" accept="image/*" @change="onFileChange" ref="fileInput"
                                style="display: none;" />
                            <MDBBtn :class="usersStore.users.profilePictureUrl ? 'd-none fw-bold' : 'd-block fw-bold'"
                                color="primary" size="sm" @click="openFileDialog">
                                <MDBIcon iconStyle="fas" icon="plus" class="me-1" />
                                Set a profile picture
                            </MDBBtn>
                            <MDBBtn :class="usersStore.users.profilePictureUrl ? 'd-block fw-bold' : 'd-none fw-bold'"
                                size="sm" @click="openFileDialog">
                                <MDBIcon iconStyle="fas" icon="pen" class="me-1" />
                                Edit my profile picture
                            </MDBBtn>
                        </div>
                    </MDBCardBody>
                </MDBCard>
                <div :class="isUser ? 'd-block' : 'd-block d-md-flex gap-3 h-75'">
                    <MDBCard :class="isUser ? 'shadow-sm w-100 mb-3' : 'shadow-sm w-100 mb-3 mb-md-0'">
                        <MDBCardHeader class="py-3">
                            <h5 class="mb-0 fw-bold">Account Information</h5>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <div class="d-flex gap-2">
                                <h6 class="fw-bold mb-0">Client Type: </h6>
                                <h6 class="mb-0 ms-auto">{{ userType(usersStore.users.userTypeId) }}</h6>
                            </div>
                            <div class="d-flex gap-2 mt-3">
                                <h6 class="fw-bold mb-0">Join Date: </h6>
                                <h6 class="mb-0 ms-auto">{{ parseDateLong(usersStore.users.activationDate) }}</h6>
                            </div>
                            <div class="d-flex gap-2 mt-3">
                                <h6 class="fw-bold mb-0">Account Status: </h6>
                                <h6 class="mb-0 ms-auto">{{ usersStore.users.isActive ? 'Active' : 'Inactive' }}</h6>
                            </div>
                        </MDBCardBody>
                        <MDBCardFooter class="d-flex justify-content-start align-items-center" v-if="isUser">
                            <MDBBtn tag="a" color="link" size="sm"
                                href="mailto:requests@enfinity.com?subject=I am requesting to change my payment information"
                                class="fw-bold text-capitalize px-3 px-md-0 py-2 text-left">
                                Contact us to update your payment information.
                            </MDBBtn>
                        </MDBCardFooter>
                    </MDBCard>
                    <MDBCard class="shadow-sm w-100 mb-3 mb-md-0">
                        <MDBCardHeader class="py-3">
                            <h5 class="mb-0 fw-bold">Account Settings</h5>
                        </MDBCardHeader>
                        <MDBCardBody class="px-2">
                            <MDBContainer fluid tag="form">
                                <h6 class="fw-bold">Update Password</h6>
                                <MDBCol class="mt-3">
                                    <MDBInput label="New Password" type="password" v-model="form.password" />
                                </MDBCol>
                                <MDBCol class="mb-2">
                                    <password-meter @score="onScore" :password="form.password" />
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput label="Confirm Password" type="password" v-model="confirmPassword"
                                        :helper="form.password && confirmPassword && !isSamePassword
                                            ? 'Password does not match!'
                                            : ''
                                            " />
                                </MDBCol>
                            </MDBContainer>
                        </MDBCardBody>
                        <MDBCardFooter class="d-flex justify-content-end align-items-center">
                            <MDBBtn @click="submit" size="sm" color="primary" :disabled="isSubmitBtnDisabled"
                                class="fw-bold text-uppercase text-nowrap">
                                Save
                            </MDBBtn>
                        </MDBCardFooter>
                    </MDBCard>
                </div>
            </div>
            <MDBCard class="shadow youtube-card" v-if="isUser">
                <MDBCardHeader class="d-flex justify-content-center align-items-center py-3">
                    <!-- <vue-feather type="youtube" :stroke-width="2.5" class="py-auto my-auto me-2" /> -->
                    <h5 class="mb-0 fw-bold text-center">YouTube Channel List</h5>
                </MDBCardHeader>
                <MDBCardBody class="py-3 px-4 pb-0 overflow-auto channel-card">
                    <template v-if="ytLoader" class="d-flex justify-content-center align-items-center">
                        <div class="image-container d-flex justify-content-center align-items-center">
                            <div class="loader"></div>
                            <img src="@/assets/VVV/vvv-loader.png" alt="Loading image">
                        </div>
                    </template>
                    <template v-else>
                        <p v-if="youtubeChannelList.length === 0" class="m-auto text-center">No channel found.</p>
                        <div v-else v-for="channel in youtubeChannelList" :key="channel.id"
                            class="d-flex justify-content-between align-items-center py-2">
                            <div class="d-flex align-items-center" style="gap: 10px;cursor: pointer;">
                                <img :src="channel.thumbnail" alt="Thumbnail" style="width: 63px; height: 63px"
                                    class="rounded-circle" />
                                <div>
                                    <p class="my-auto text-left fw-bold" style="font-size: 14px;">{{ channel.channelName
                                        }}
                                    </p>
                                    <p class="my-auto text-left" style="font-size: 13px;">{{ channel.channelUsername }}
                                    </p>
                                    <p class="my-auto text-left" style="font-size: 13px;">{{ channel.subscriberCount }}
                                        subscribers</p>
                                </div>
                            </div>
                            <vue-feather type="trash" :stroke-width="2.5" size="16" class="py-1 px-0 ms-5"
                                style="cursor: pointer;" @click="toggleDeleteModal(channel.id)"
                                title="Delete channel" />
                        </div>
                    </template>
                </MDBCardBody>
                <MDBCardFooter class="d-flex justify-content-center">
                    <!-- <router-link :to="{ name: 'NewClaim' }" custom v-slot="{ href, navigate }">
                        <MDBBtn tag="a" color="primary" @click.stop="navigate" :href="href" size="sm" class="fw-bold">
                            Add Channel via New Claim
                        </MDBBtn>
                    </router-link> -->
                    <MDBBtn class="fw-bold" @click="ytChannelModal = true" color="primary" size="sm">
                        Add YouTube Channel
                    </MDBBtn>
                </MDBCardFooter>
            </MDBCard>
        </div>
        <MDBModal id="ytChannelModal" tabindex="-1" labelledby="ytChannelModalLabel" v-model="ytChannelModal" scrollable
            :static-backdrop="true">
            <MDBModalHeader class="px-4">
                <MDBModalTitle class="fw-bold" id="ytChannelModalLabel"> Add YouTube Channel </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody class="px-4">
                <div class="d-flex justify-content-center flex-column">
                    <div class="input-group w-100">
                        <MDBBtn color="primary" size="sm">
                            <i class="fab fa-youtube fa-fw pb-1"></i>
                        </MDBBtn>
                        <input v-model="channelUrl" type="text" class="form-control" placeholder="YouTube Channel URL"
                            @keyup.enter="previewVideo" />
                        <MDBBtn class="btn btn-primary fw-bold" type="button" @click="previewVideo">Preview
                        </MDBBtn>
                    </div>
                </div>
                <template v-if="channelDetails.channelName">
                    <div class="d-flex justify-content-center align-items-center mt-3" v-if="channelLoader">
                        <div class="image-container d-flex justify-content-center align-items-center">
                            <div class="loader"></div>
                            <img src="@/assets/VVV/vvv-loader.png" alt="Loading image">
                        </div>
                    </div>
                    <div v-else class="d-flex flex-column justify-content-center overflow-auto">
                        <div class="d-flex gap-2 mt-3 justify-content-center">
                            <div
                                style="width: 63px; height: 63px; border-radius: 50%; overflow: hidden; display: flex; align-items: center; justify-content: center;">
                                <img :src="channelDetails.thumbnail" alt="Thumbnail"
                                    style="width: auto; height: 100%;" />
                            </div>
                            <div class="my-auto video-details">
                                <p class="my-auto text-left fw-bold" style="font-size: 14px;">{{
                                    channelDetails.channelName }}</p>
                                <p class="my-auto text-left" style="font-size: 13px;">{{
                                    channelDetails.channelUsername }}</p>
                                <p class="my-auto text-left" style="font-size: 13px;">{{ channelDetails.subscriberCount
                                    }} subscribers</p>
                            </div>
                        </div>
                    </div>
                </template>
            </MDBModalBody>
            <MDBModalFooter class="gap-2 py-3 px-4">
                <MDBBtn class="fw-bold" size="sm" @click="ytChannelModal = false"> Close </MDBBtn>
                <MDBBtn class="fw-bold" size="sm" color="primary" @click="submitChannel" :disabled="channelUrl == '' || !isChannel">Confirm Add</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        <MDBToast v-model="errorToast" :delay="2000" autohide position="top-right" appendToBody stacking width="350px"
            color="danger" text="white" icon="fas fa-check fa-lg me-2">
            <template #title> Error </template>
            {{ errorToastMessage }}
        </MDBToast>
        <MDBToast v-model="toastObject.state" :delay="2000" autohide position="top-right" appendToBody stacking
            width="350px" :color="toastObject.color" text="white" :icon="toastObject.icon">
            <template #title>
                {{ toastObject.title }}
            </template>
            {{ toastObject.message }}
        </MDBToast>
        <MDBModal id="deleteModal" tabindex="-1" labelledby="deleteModalLabel" v-model="deleteModal">
            <MDBModalHeader>
                <MDBModalTitle class="fw-bold" id="deleteModalLabel"> Delete Channel </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>Are you sure you want to delete this channel?</MDBModalBody>
            <MDBModalFooter class="gap-2">
                <MDBBtn class="btn fw-bold" size="sm" @click="deleteModal = false">Close</MDBBtn>
                <MDBBtn class="btn btn-danger fw-bold" size="sm" @click="confirmDeletion()">Confirm Delete
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    </VVVLayoutNew>
</template>

<script setup>
import {
    MDBCol,
    MDBBtn,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBCardFooter,
    MDBInput,
    MDBToast,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBContainer,
    MDBIcon,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter
} from "mdb-vue-ui-kit";
import VVVLayoutNew from "@/views/VVVNew/VVVLayoutNew.vue";
import { computed, reactive, ref, onMounted, watch } from "vue";
import axios from "axios";
import PasswordMeter from "vue-simple-password-meter";
import { useTitle } from "@vueuse/core";
import { useRouter } from 'vue-router';
import { parseDateLong } from '@/helpers/parseDate';
import { useUsersStore } from "@/store/usersStore";
import { useUserDetailStore } from "../../store/userDetailStore";
import { storeToRefs } from "pinia";
import VueFeather from 'vue-feather';
import { GetUserSocialMediaPages } from "@/services/UserSocialMediaPages/GetUserSocialMediaPages";
import { DeleteUserSocialMediaPages } from "@/services/UserSocialMediaPages/DeleteUserSocialMediaPages";
import { isYouTubeUrl, fetchChannelDetailsDirectly, fetchChannelDetailsBatch } from "@/helpers/youtubeScraper";
import { useYoutubeStore } from "@/store/youtubeStore";
import { PostUserSocialMediaPages } from "@/services/UserSocialMediaPages/PostUserSocialMediaPages";

const usersStore = useUsersStore();
const { isUser } = storeToRefs(usersStore);

const userDetailStore = useUserDetailStore();
const { userDetail } = storeToRefs(userDetailStore);

onMounted(() => {
    watch(userDetail, async (newUserDetail) => {
        await usersStore.getUsers({ UserId: userDetailStore.userDetail.id });
        useTitle(`${newUserDetail.firstName}'s Profile | Very Viral Videos`);
    });
});


const form = reactive({
    password: "",
});

const toastObject = ref({
    state: false,
    message: "",
    color: "",
    icon: "",
    title: "",
});

let loadingSpinner = ref(false);
const submit = () => {
    let formData = new FormData();
    formData.append("password", form.password);
    loadingSpinner.value = true;

    axios
        .put("api/users/updateSettings", formData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(function (response) {
            if (response.status == 200) {
                toastObject.value.title = "Success!";
                toastObject.value.state = true;
                toastObject.value.message = "Successfully submitted!";
                toastObject.value.color = "success";
                toastObject.value.icon = "fas fa-check fa-lg me-2";
                form.password = "";
                confirmPassword.value = "";
                loadingSpinner.value = false;
            } else {
                toastObject.value.title = "Error!";
                toastObject.value.state = true;
                toastObject.value.message = "An error occurred during request.";
                toastObject.value.color = "danger";
                toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
                loadingSpinner.value = false;
            }
        })
        .catch(function (error) {
            toastObject.value.title = "Error!";
            toastObject.value.state = true;
            toastObject.value.message = "An error occurred during request: " + error;
            toastObject.value.color = "danger";
            toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
            loadingSpinner.value = false;
        });
};

const confirmPassword = ref("");
const isSamePassword = computed(
    () =>
        form.password == confirmPassword.value &&
        form.password != "" &&
        confirmPassword.value != ""
);

const isSubmitBtnDisabled = computed(
    () =>
        !isSamePassword.value ||
        score.value < 3
);

const score = ref(null);
const onScore = (payload) => {
    score.value = payload.score;
};

const router = useRouter();
const goBack = () => {
    router.go(-1);
};

const avatarLetter = computed(() => {
    return usersStore.users?.firstName?.charAt(0).toUpperCase() || '';
});

const fileInput = ref(null);
const openFileDialog = () => {
    if (fileInput.value) {
        fileInput.value.value = null;
        fileInput.value.click();
    }
};

const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        const reader = new FileReader();

        reader.onload = (event) => {
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height * maxWidth) / width);
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width * maxHeight) / height);
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    const resizedFile = new File([blob], file.name, {
                        type: file.type,
                        lastModified: Date.now(),
                    });
                    resolve(resizedFile);
                }, file.type);
            };
        };

        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
    });
};

const onFileChange = async (event) => {
    const file = event.target.files[0];

    const resizedFile = await resizeImage(file, 200, 200);

    await uploadProfilePicture(resizedFile);

    const reader = new FileReader();
    reader.onload = (e) => {
        usersStore.users.pictureFile = e.target.result;
    };
    reader.readAsDataURL(resizedFile);
};

const uploadProfilePicture = async (file) => {
    const userId = usersStore.users.id;
    const formData = new FormData();

    formData.append('UserId', userId);
    formData.append('File', file);

    loadingSpinner.value = true;
    try {
        const response = await axios.patch(`/api/users/${userId}/profilePicture`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.status === 200) {
            await setNewProfilePicture(response.data)
            toastObject.value.title = "Success!";
            toastObject.value.state = true;
            toastObject.value.message = "Profile picture updated successfully!";
            toastObject.value.color = "success";
            toastObject.value.icon = "fas fa-check fa-lg me-2";
        } else {
            toastObject.value.title = "Error!";
            toastObject.value.state = true;
            toastObject.value.message = "An error occurred during the request.";
            toastObject.value.color = "danger";
            toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
        }
    } catch (error) {
        toastObject.value.title = "Error!";
        toastObject.value.state = true;
        toastObject.value.message = "An error occurred during the request: " + (error.response ? error.response.data : error);
        toastObject.value.color = "danger";
        toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
    } finally {
        loadingSpinner.value = false;
    }
};

const setNewProfilePicture = async (userId) => {
    const res = await usersStore.getUserById(userId);
    usersStore.users = { ...usersStore.users, profilePictureUrl: res.profilePictureUrl };
    const userDetailStore = useUserDetailStore();
    userDetailStore.userDetail = { ...userDetailStore.userDetail, profilePictureUrl: res.profilePictureUrl };
}

const userType = (userTypeId) => {
    if (userTypeId === 1) {
        return "Admin";
    }
    if (userTypeId === 2) {
        return "User";
    }
    if (userTypeId === 3) {
        return "Distributor";
    }
    if (userTypeId === 4) {
        return "Super Admin";
    }
}

watch(() => usersStore.users, (newUser) => {
    if (isUser.value) {
        getYoutubeChannelInformationList(newUser.id);
    }
});

const youtubeChannelList = ref([]);
const ytLoader = ref(false);

const getYoutubeChannelInformationList = async (userId) => {
    ytLoader.value = true;
    try {
        const response = await GetUserSocialMediaPages(userId);
        const channels = response.data;

        const channelIds = channels.map((channel) => channel.channelId);

        const detailedChannels = await fetchChannelDetailsBatch(channelIds);

        youtubeChannelList.value = channels.map((channel) => {
            const details = detailedChannels.find(detail => detail.channelId === channel.channelId);
            return {
                ...channel,
                ...details,
            };
        });

        ytLoader.value = false;
    } catch (error) {
        console.error("Error fetching YouTube channel information:", error);
        ytLoader.value = false;
    }
};

const deleteModal = ref(false);
const channel = ref("");

const toggleDeleteModal = (id) => {
    deleteModal.value = true;
    channel.value = id;
};

const confirmDeletion = () => {
    deleteChannel(channel.value);
};

const deleteChannel = async (id) => {
    await DeleteUserSocialMediaPages(id);
    getYoutubeChannelInformationList(userDetail.value.id);
    deleteModal.value = false;
};

const youtubeStore = useYoutubeStore();
const { youtubeVideo } = storeToRefs(youtubeStore);

const ytChannelModal = ref(false);
const channelUrl = ref(youtubeVideo.value.url);
const channelLoader = ref(false);

const errorToast = ref(false);
const errorToastMessage = ref("Error");

const channelDetails = ref({
    channelId: "",
    channelName: "",
    channelUsername: "",
    thumbnail: "",
    subscriberCount: ""
});

const isChannel = ref(false);
const previewVideo = async () => {
    if (channelUrl.value === "") {
        errorToastMessage.value = "Please enter a YouTube URL to preview.";
        errorToast.value = true;
        return;
    }
    if (!isYouTubeUrl(channelUrl.value)) {
        errorToastMessage.value = "Please enter a valid YouTube URL.";
        errorToast.value = true;
        return;
    }

    channelLoader.value = true;

    try {
        const channelData = await fetchChannelDetailsDirectly(channelUrl.value);
        channelDetails.value = {
            channelId: channelData.channelId,
            channelName: channelData.channelName,
            channelUsername: channelData.channelUsername,
            thumbnail: channelData.thumbnail,
            subscriberCount: channelData.subscriberCount
        };
        channelLoader.value = false;
        isChannel.value = true;
    } catch (error) {
        isChannel.value = false;
        console.error("Error fetching channel details:", error);
        errorToastMessage.value = "Failed to load channel details. Please try again.";
        errorToast.value = true;
        channelLoader.value = false;
    }
};

const submitChannel = async () => {
    try {
        const channelInfo = {
            UserId: userDetail.value.id,
            Link: channelUrl.value,
            ChannelId: channelDetails.value.channelId,
            SocialMediaPlatformId: 4,
        };

        const submitResponse = await PostUserSocialMediaPages(channelInfo);

        if (submitResponse === 1) {
            errorToastMessage.value = "YouTube channel already exists.";
            errorToast.value = true;
        } else if (submitResponse) {
            ytChannelModal.value = false;
            channelDetails.value = {};
            channelUrl.value = "";
            toastObject.value.title = "Success!";
            toastObject.value.state = true;
            toastObject.value.message = "YouTube channel added successfully.";
            toastObject.value.color = "success";
            toastObject.value.icon = "fas fa-check fa-lg me-2";
            getYoutubeChannelInformationList(userDetail.value.id);
        } else {
            errorToastMessage.value = "Failed to submit channel.";
            errorToast.value = true;
        }
    } catch (error) {
        console.error("Error submitting channel:", error);
        errorToastMessage.value = "Failed to submit channel.";
        errorToast.value = true;
    }
};
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.youtube-card {
    width: 80%;
}

.social-title {
    width: 25%;
}

.social-link {
    width: 75%;
}

.channel-card {
    height: 5vh;
}

@media screen and (max-width: 767px) {
    .youtube-card {
        width: 100%
    }

    .social-title {
        width: 45%;
    }

    .social-link {
        width: 55%;
    }

    .channel-card {
        height: 80vh;
    }
}

.default-avatar {
    width: 80px;
    height: 80px;
    background-color: var(--primary);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 40px;
    font-weight: bold;
}

.profile-avatar {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    object-fit: cover;
}

.avatar-letter {
    text-transform: uppercase;
}

#table-container {
    flex: 1 1 auto;
}
</style>
